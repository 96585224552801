import { Modal } from 'shared/ui/Modal/Modal';
import ExistPage from './pages/ExistPage';
import GiftAwaitPage from './pages/GiftAwaitPage';
import ReceivedGiftPage from './pages/ReceivedGiftPage';
import BackGroundLogoOnly from 'shared/ui/BackGroundLogoOnly/BackGroundLogoOnly';
import { useSelector } from 'react-redux';

const EndGame = () => {

  const endGameScreenIndex = useSelector((state) => (state as any).game.endGameScreenIndex);

  const pages = [
    <ExistPage />,
    <GiftAwaitPage />,
    <ReceivedGiftPage />,
  ];

  return (
    <>
      <Modal>{pages[endGameScreenIndex]}</Modal>
      <BackGroundLogoOnly />
    </>
  );
};

export default EndGame;
