import axios from 'axios';

type TProps = {
  email: string
  firstName: string
  mobilePhone: string
  customFields: {
    iMHammam: number
  }
};

export interface SuccessData {
  attributes: {
    createdAt: string;
    email: string;
    grade: number;
    name: string;
    points: number;
    updatedAt: string;
  };
  id: 3;
}

export type ErrorData = {
  data?: null;
  error?: {
    details: any;
    message: string;
    name: string;
    status: number;
  };
};

export const postPlayer = (props: TProps) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/players/`,
      { data: props },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      }
    )
    .then(({ data: { data } }: { data: { data: SuccessData } }) => ({
      ...data,
      status: 'success',
    }))
    .catch((err) => {
      if (err?.response?.data?.error?.name === 'ValidationError')
        return {
          ...err.response.data,
          status: 'usedEmail',
        };
      return { status: 'error' };
    });
};

type TMindboxProps = {
  email: string
  firstName: string
  mobilePhone: string
  customFields: {
    iMHammam: number
  }
}

function getCookieValue(name: string) 
{
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);

  if (match) {
    return match[2];
  }
}

export const postMindbox = (props: TMindboxProps) => {

  return axios
    .post(
      `https://api.mindbox.ru/v3/operations/sync?endpointId=spiritfit-website&operation=${process.env.REACT_APP_MINDBOX_OPERATION}`,
      { customer: props },
      {
        headers: {
          'Authorization': `SecretKey ${process.env.REACT_APP_MINDBOX_AUTHORIZATION}`,
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
          "Cookie": getCookieValue("mindboxDeviceUUID")
        }
      }
    )
    .then(({ data: { data } }: { data: { data: SuccessData } }) => ({
      ...data,
      status: 'success',
    }))
    .catch((err) => {
      if (err?.response?.data?.error?.name === 'ValidationError')
        return {
          ...err.response.data,
          status: 'usedEmail',
        };
      return { status: 'error' };
    });
}