import logo_desktop from '../assets/desktop/misc/logo.png';
import girl1 from '.././assets/desktop/character/girl1.png';
import girl2 from '../assets/desktop/character/girl2.png';
import boy from '../assets/desktop/character/boy.png';
import HammamGameEngine from './gameEngine';
import { useDispatch, useSelector } from 'react-redux';
import PositionsX from 'shared/constants/Positions';
import getRandomInt from 'shared/tools/getRandomInt';
import { setIsTimeUp, strategyScreen } from 'app/slices/gameSlice';
import { useEffect, useState } from 'react';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';

const pictures = {
  logo: logo_desktop,
};

// 20500 - 30
// 14000 - 20
// 6833  - 10
const OriginMinutes = [20500, 14000, 6833];

function Game() {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'startGameHammam',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const scale = useSelector((state) => (state as any).gameProperties.scale);
  const level = useSelector((state) => (state as any).game.level);

  const dispatch = useDispatch();

  const [randomPositions, setRandomPositions] = useState(PositionsX[level][getRandomInt(0, PositionsX.length - 1)]);
  const [minutes, setMinutes] = useState<number>(OriginMinutes[level]);

  useEffect(() => {
    const positions = PositionsX[level];

    setRandomPositions(positions[getRandomInt(0, positions.length - 1)]);
    setMinutes(OriginMinutes[level]);
  }, [level, minutes])

  const onTimeIsUp = () => {

    dataLayerPush({
        eCategory: 'UX',
        eAction: 'timeIsUp',
        eLabel: 'https://spiritfit.ru/',
        eNI: false,
        event: 'GAEvent',
      });

    dispatch(setIsTimeUp());
    dispatch(strategyScreen())
  }

  return (
    <>
      <HammamGameEngine 
        canvas={{
          h: 694,
          w: 1290,
        }}
        logo={{
          h: 107,
          w: 160,
        }}
        girl={{
          w: 223,
          h: 360
        }}
        positions={randomPositions}
        characters={[girl1, girl2, boy]}
        minute={minutes} 
        dTime={10}
        pictures={pictures}
        scale={scale}
        points={0}
        onTargetCaptured={() => {
          if (level > -1 && level < 3) {
            dataLayerPush({
              eCategory: 'UX',
              eAction: 'ksyushaCaptured',
              eLabel: 'https://spiritfit.ru/',
              eNI: false,
              event: 'GAEvent',
            });

            dispatch(strategyScreen())
          }
        }}
        onTimeIsUp={onTimeIsUp}
      />
    </>
  );
}

export default Game;
