import { FieldError } from 'react-hook-form';
import st from './Checkbox.module.scss';

interface CheckboxProps extends Record<string, any> {
  id: string;
  label: string;
  error?: FieldError;
  rest?: any;
}

export const Checkbox = ({ id, label, error, ...rest }: CheckboxProps) => (
  <div className={st.wrapper}>
    <input 
      className={st.checkbox} 
      data-error={!!error} 
      type="checkbox" 
      id={id} 
      {...rest} />
    <label htmlFor={id}>
      <span />
      {label}
    </label>
  </div>
);
