import { createSlice } from '@reduxjs/toolkit';

export const gamePropertiesSlice = createSlice({
  name: 'gameProperties',
  initialState: {
    scale: 1,
  },
  reducers: {
    defineScale: (state, action) => {
      state.scale = action.payload;
    },
  },
});

export const { defineScale } = gamePropertiesSlice.actions;

export default gamePropertiesSlice.reducer;
