import { Button } from 'shared/ui/Button/Button';
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import style from './GameRuleShortScreen.module.scss';
import { useDispatch } from 'react-redux';
import { gameRuleScreen, gameScreen } from 'app/slices/gameSlice';
import BackGroundLogoOnly from 'shared/ui/BackGroundLogoOnly/BackGroundLogoOnly';
import useDataLayerPush, {dataLayerPush} from "../../../shared/hooks/useDataLayerPush";

const GameRuleShortScreen = () => {
  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showGameShortRulesScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const dispatch = useDispatch();

  const onStart = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGameStart3',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameScreen());
  };

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h2 className={st.header}>Краткие правила игры</h2>
          <p className={st.text}>
            <b>Твоя задача:</b> найти Ксюшу в хаммаме. Чтобы пройти уровень, нужно развеять весь пар
            и нажать на Ксюшу.
            <br />
            <br />
            <b>Управление.</b> Чтобы удалить пар на телефоне — свайп по экрану. На компьютере — клик
            кнопкой мыши. Если увидишь Ксюшу, нажимай на нее пальцем или кликай кнопкой мыши.
            <br />
            <br />В игре 3 уровня, с каждым раундом время на поиски уменьшается:
            <ul className={style.list}>
              <li>1-й уровень — 30 секунд;</li>
              <li>2-й уровень — 15 секунд;</li>
              <li>3-й уровень — 10 секунд.</li>
            </ul>
            За каждый пройденный уровень подарим промокод<br/>на скидку на абонемент Spirit. Fitness —
            чем больше уровней, тем выше скидка.
          </p>
          <div className={st.buttons_wrapper} style={{ marginTop: 12 }}>
            <Button label={'Старт'} onClick={onStart}/>
          </div>
          <a 
            target="_blank"
            href="https://cdn.email-image.com/spiritfitness/spirit_fitness_rules_game.pdf"
            className={st.text_link} 
            style={{ marginTop: 23, cursor: 'pointer' }} rel="noreferrer">
            Полные правила
          </a>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

export default GameRuleShortScreen;
