import { useEffect } from 'react';

type Props = {
  eCategory: string;
  eAction: string;
  eLabel: string;
  eNI: boolean;
  event: string;
};

export function dataLayerPush(props: Props) {
  ((window as any).dataLayer = (window as any).dataLayer || []).push(props);
  console.log('dataLayerPush');
}

function useDataLayerPush(props: Props) {
  useEffect(() => {
    dataLayerPush(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useDataLayerPush;
