import BackGround from 'shared/ui/BackGround/BackGround';
import st from './PortraitScreen.module.scss';
import phone from 'shared/assets/svg/phone.svg';

const PortraitScreen = () => {
  return (
    <>
      <div className={st.wrapper}>
        <img src={phone} alt="Повернутый телефон" />
        <p>Переверните экран для продолжения игры</p>
        <p style={{ marginTop: 15 }}>Стирайте осторожно, чтобы не свернуть игру</p>
      </div>
      <BackGround isRotated />
    </>
  );
};

export default PortraitScreen;
