import { Routing } from "pages";
import { withProviders } from "./providers";
import './index.scss';
import { useEffect } from "react";

const App = () => {

  useEffect(() => {
    const setVH = () => {
      const vh = window.innerHeight * 0.01;
      const vw = window.innerWidth * 0.01;
      document?.documentElement.style.setProperty('--vh', `${vh}px`);
      document?.documentElement.style.setProperty('--vw', `${vw}px`);
    };
    setVH();
    window.addEventListener('resize', setVH);
    window.scrollTo(0, 0);

    return () => {
      window.removeEventListener('resize', setVH);
    };
  }, []);


  return (
    <div className="app steam">
      <Routing />
    </div>
  );
}

export default withProviders(App);
