/* eslint-disable jsx-a11y/anchor-is-valid */
import { endGameScreen, feedbackScreen, gameRuleShortScreen, gameScreen, incrementLevel, resetIsTimeUp, resetLevel, startExistPage, startReceivedGiftPage } from 'app/slices/gameSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'shared/ui/Button/Button';
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import style from './StrategyScreen.module.scss';
import useDataLayerPush, {dataLayerPush} from 'shared/hooks/useDataLayerPush';
import BackGroundLogoOnly from 'shared/ui/BackGroundLogoOnly/BackGroundLogoOnly';

const FirstLevel = () => {

  const dispatch = useDispatch();

  useDataLayerPush({
      eCategory: 'UX',
      eAction: 'startedFirstLevel',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

  const onContinue = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'firstLevelWin',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(incrementLevel())
    dispatch(gameScreen())
  }
  
  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h4 className={style.header}>
            Вот она! У тебя классно
            <br />
            получается!
          </h4>
          <p className={st.text}>
            А Ксюша снова спряталась в пару… Теперь найди
            <br />
            ее за 20 секунд.
          </p>
          <div className={st.buttons_wrapper} style={{ marginTop: 10 }}>
            <Button label={'Продолжить'} onClick={() => onContinue()} />
          </div>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

const TimeOut = () => {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'TimeOutScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });
  
  const dispatch = useDispatch();

  const onPlayAgain = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickPlayAgain',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(gameRuleShortScreen())
    dispatch(resetLevel())
    dispatch(resetIsTimeUp())
  }

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h4 className={style.header}>Эх, время вышло…</h4>
          <p className={st.text}>
            Кажется, Ксюша уже не первый раз прячется в хаммаме вместо тренировки и уже знает все
            скрытые места.
            <br />
            <br />
            Попробуй еще раз — у тебя точно получится!
          </p>
          <div className={st.buttons_wrapper} style={{ marginTop: 10 }}>
            <Button label={'Играть ещё раз'} onClick={onPlayAgain} />
          </div>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

const GetGift = () => {
  
  const dispatch = useDispatch();
  const isUserInBase = useSelector((state) => (state as any).game.isUserInBase);

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'secondOrLastLevelFailed',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const onRetry = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'retryGame',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(gameRuleShortScreen())
    dispatch(resetLevel())
    dispatch(resetIsTimeUp())
  }

  const onGetGift = () => {

    dataLayerPush({
      eCategory: 'UX',
      eAction: 'getGift',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    
    if (!isUserInBase) {
      dispatch(feedbackScreen())
      return;
    }

    dispatch(endGameScreen())
  }

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h4 className={style.header}>Чуть-чуть не хватило!</h4>
          <p className={st.text}>
            У тебя получилось найти Ксюшу в прошлом раунде, поэтому держи свой подарок. А какой —
            расскажем в письме!
          </p>
          <div className={st.buttons_wrapper} style={{ marginTop: 10 }}>
            <Button label={'Забрать подарок'} onClick={() => onGetGift()} />
          </div>
          <p className={st.text} style={{ marginTop: 24, marginBottom: 18 }}>
            Или попробуй выиграть главный приз!
          </p>
          <a style={{ cursor: 'pointer' }} className={st.text_link} onClick={() => onRetry()}>
            Играть снова
          </a>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

const SecondLevel = () => {
  
  const dispatch = useDispatch();

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'startedSecondLevel',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const onNextLevel = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'secondLevelWin',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(incrementLevel())
    dispatch(gameScreen())
  }

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h4 className={style.header}>Прятаться больше некуда…</h4>
          <p className={st.text}>Остался один раунд в 10 секунд — и главный приз твой!</p>
          <div className={st.buttons_wrapper} style={{ marginTop: 10 }}>
            <Button label={'Поехали'} onClick={() => onNextLevel()} />
          </div>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

const StrategyScreen = () => {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showGameWinning',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const dispatch = useDispatch();

  const level = useSelector((state) => (state as any).game.level);
  const isTimeUp = useSelector((state) => (state as any).game.isTimeUp);
  const isUserInBase = useSelector((state) => (state as any).game.isUserInBase);
  const isPromoCodeReceived = useSelector((state) => (state as any).game.isPromoCodeReceived);

  if (!isTimeUp && level === 0)
    return <FirstLevel/>

  if (!isTimeUp && level === 1)
    return <SecondLevel/>

  if (!isTimeUp && level === 2) 
  {
    if (isUserInBase) {
      if (isPromoCodeReceived) {
        dispatch(startReceivedGiftPage())
        dispatch(endGameScreen())
        return;
      }
      dispatch(startExistPage())
      dispatch(endGameScreen())
    } else {
      dispatch(feedbackScreen())
    }
  }

  if (isTimeUp && level === 0)
    return <TimeOut/>

  if (isTimeUp && (level === 1 || level === 2))
    return <GetGift />
};

export default StrategyScreen;
