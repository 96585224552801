import { configureStore } from '@reduxjs/toolkit';
import gameSlice from './slices/gameSlice';
import gamePropertiesSlice from './slices/gamePropertiesSlice';

export const store = configureStore({
  reducer: {
    game: gameSlice,
    gameProperties: gamePropertiesSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
