import { useDispatch } from 'react-redux';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';
import { gameRuleScreen } from 'app/slices/gameSlice';
import { Button } from 'shared/ui/Button/Button';
import style from './LaunchScreen.module.scss';
import logoImage from 'shared/assets/desktop/misc/logo.png';
import steamGirlImage from 'shared/assets/desktop/character/steam_girl.png';
import appStoreImage from 'shared/assets/svg/appstore.svg';
import googlePlayImage from 'shared/assets/svg/google_play.svg';
import dzen from 'shared/assets/svg/dzen.svg';
import telegram from 'shared/assets/svg/telegram.svg';
import vk from 'shared/assets/svg/vk.svg';
import tiktok from 'shared/assets/svg/tiktok.svg';

type SocialIconProps = {
  logo: string
  url: string
  className?: string
}

const SocialIcon = ({logo, url}: SocialIconProps) => {
  return (
    <a href={url} target='_blank' rel="noreferrer">
      <img src={logo} alt=""/>
    </a>
  )
}

const LaunchScreen = () => {
  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showInitialScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const dispatch = useDispatch();

  const onStart = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGameStart1',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameRuleScreen());
  };

  return (
    <>
      <div className={style.back}>
        <div className={style.wrapper}>
          <div className={style.left_side}>
            <img className={style.logo} src={logoImage} alt="" />
            <h2 className={style.header}>Получи скидку за помощь тренеру!</h2>
            <article className={style.text}>
              Тренировка началась, а Ксюши всё нет. Странно... Тренер точно помнит, как она выходила
              из хаммама.
            </article>
            <article className={style.text}>
              «Где же Ксюша?» — тихо произносит он. «Спряталась в хаммаме», — отвечает чей-то
              голос…
            </article>
            <article className={style.text}>
              …Все мы иногда хотим посидеть в хаммаме вместо тренировки.
            </article>
            <div className={style.button_container}>
              <Button label={'Начать игру'} onClick={onStart}/>
            </div>
          </div>
          <div className={style.right_side}>
            <img className={style.character} src={steamGirlImage} alt="" />
            <div className={style.row}>
              <div className={style.platforms}>
                <SocialIcon className={style.store} logo={appStoreImage} url='https://apps.apple.com/ru/app/spirit/id1329534784?utm_source=landing&utm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=app'/>
                <SocialIcon className={style.store} logo={googlePlayImage} url='https://play.google.com/store/apps/details?id=ru.spirit.android&utm_source=landingutm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=app'/>
              </div>
              <div className={style.socials}>
                <SocialIcon logo={dzen} url='https://spiritfit-ru.turbopages.org/promo/media/id/6017f57288bc0d2cb7405dc6?utm_source=landing&utm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=social_media'/>
                <SocialIcon logo={telegram} url='https://t.me/spiritfitness_official?utm_source=landing&utm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=social_media'/>
                <SocialIcon logo={vk} url='https://vk.com/spiritmoscow?utm_source=landing&utm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=social_media'/>
                <SocialIcon logo={tiktok} url='https://www.tiktok.com/@spiritfitness?utm_source=landing&utm_medium=game&utm_campaign=ooc_game_spiritfit_promo2_game-mechanic&utm_content=social_media'/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.gradient}></div>
    </>
  );
};

export default LaunchScreen;
