/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import girl from 'shared/assets/desktop/character/steam_girl.png';
import style from './../EndGameScreen.module.scss';
import st from './../../screens.module.scss';
import { useDispatch } from 'react-redux';
import { gameRuleShortScreen, resetLevel, resetIsTimeUp } from 'app/slices/gameSlice';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';

const ExistPage = () => {

  const dispatch = useDispatch();

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showExitGameScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const onRetryGame = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickRetryGame',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(gameRuleShortScreen());
    dispatch(resetLevel());
    dispatch(resetIsTimeUp());
  }

  return (
    <>
      <img className={style.steam_girl} src={girl} />
      <h2 className={st.header} style={{ fontSize: 24, marginBottom: 9, marginTop: 24 }}>А вот и Ксюша!</h2>
      <h2 className={st.header}>Главный приз твой!</h2>
      <p className={st.text}>Письмо с подробностями уже улетело на твою почту.<br/>Проверяй!</p>
      <a 
        style={{ cursor: 'pointer', marginBottom: 69 }} 
        className={st.text_link} 
        onClick={() => onRetryGame()}>
        Играть снова
      </a>
    </>
  );
};

export default ExistPage;
