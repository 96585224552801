/* eslint-disable jsx-a11y/alt-text */
import girl from 'shared/assets/desktop/character/steam_girl.png';
import style from './../EndGameScreen.module.scss';
import st from './../../screens.module.scss';
import useDataLayerPush from 'shared/hooks/useDataLayerPush';

const GiftAwaitPage = () => {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'giftAwaitScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  return (
    <>
      <img className={style.steam_girl} src={girl} />
      <h2 className={st.header} style={{ fontSize: 24, marginBottom: 43, marginTop: 20 }}>
        Письмо улетело — проверяй!
      </h2>
    </>
  );
};

export default GiftAwaitPage;
