import { useRef, useState } from 'react';
import { Stage, Layer, Rect, Text, Line } from 'react-konva';
import { useInterval } from 'shared/hooks/useInterval';
import useImage from 'use-image';
import millisecondsToMinutesAndSeconds from 'shared/tools/millisecondsToMinutesAndSeconds';
import st from './index.module.scss';
import { TSizeEntity } from './types';
import { useSelector } from 'react-redux';
import steamPNG from '../assets/desktop/bg/steam_light.png';
import { KonvaEventObject } from 'konva/lib/Node';

type Props = {
  canvas: TSizeEntity;
  logo: TSizeEntity;
  minute: number;
  dTime: number;
  characters: Array<string>;
  pictures: {
    logo: string;
  };
  girl: TSizeEntity;
  positions: Array<number>;
  scale: number;
  points: number;
  onTargetCaptured: () => void;
  onTimeIsUp: () => void;
};

function HammamGameEngine({
  canvas,
  logo,  
  girl,
  minute,
  dTime,
  characters,
  positions,
  pictures,
  scale,
  onTimeIsUp,
  onTargetCaptured
}: Props) {
  const [timer, setTimer] = useState(1.5 * minute);

  const draw = () => {
    setTimer((prev) => prev - dTime);
  };

  const level = useSelector((state) => (state as any).game.level);

  const spriteScale = 1.4;
  const eraserCount = 70;

  const [lines, setLines] = useState<{points:number[]}[]>([]);
  const isDrawing = useRef(false);

  const steamImage = useImage(steamPNG)[0];

  useInterval(
    draw,
    () => {
      onTimeIsUp();
    },
    dTime,
    [timer, steamImage],
    timer <= dTime
  );

  function isPointInsideRectangle(point: {x: number, y: number}, rectangle: {x: number, y: number, width: number, height: number }) {
    return (
      point.x >= rectangle.x &&
      point.x <= rectangle.x + rectangle.width &&
      point.y >= rectangle.y &&
      point.y <= rectangle.y + rectangle.height
    );
  }

  const handleMouseDown = (e : any) => {
    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    setLines([...lines, { points: [pos.x / scale, pos.y / scale] }]);

    const targetPositionX = positions[0] * scale;

    if(isPointInsideRectangle(pos, {x: targetPositionX, y: (canvas.h * scale - girl.h * scale), width: girl.w * scale, height: girl.h * scale})){
      let countTrigger = 0;
      lines.forEach( line => {
        for(let i = 0; i < (line.points.length / 2); i += 2 ){
          if(isPointInsideRectangle(
            {x: line.points[i] * scale, y: line.points[i+1] * scale}, 
            {x: targetPositionX, y: (canvas.h * scale - girl.h * scale), width: girl.w * scale, height: girl.h * scale})){
            countTrigger++;
          }
        }
      })
      if(countTrigger >= eraserCount * scale){
        onTargetCaptured()
      }
    }
  };

  var pointerCount = 0;

  const handleMouseMove = (e: any) => {
    
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();

    const point = stage.getPointerPosition();
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([point.x / scale, point.y / scale]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  const locationClasses = ['location-first', 'locatrion-second', 'location-third']

  const firstSprite = useImage(characters[1])[0];
  const secondSprite = useImage(characters[2])[0];
  const targetSprite = useImage(characters[0])[0];

  return (
    <div className={st.wrapper}>
      <Stage 
        className={locationClasses[level]} 
        width={canvas.w * scale} 
        height={canvas.h * scale} 
        scaleX={scale} 
        scaleY={scale}
        onPointerDown={handleMouseDown}
        onPointerMove={handleMouseMove}
        onPointerUp={handleMouseUp}>
        <Layer>
          {/* characters */}
          {steamImage && 
          <>
            <Rect
              fillPatternImage={firstSprite}
              x={positions[1]}
              y={canvas.h - 320 * spriteScale}
              scale={{ x: spriteScale, y: spriteScale }}
              width={164}
              height={320}
              />
            <Rect
              fillPatternImage={secondSprite}
              x={positions[2]}
              y={canvas.h - 373 * spriteScale}
              scale={{ x: spriteScale, y: spriteScale }}
              width={192}
              height={373}
            />
            {/* target */}
            <Rect
              fillPatternImage={targetSprite}
              x={positions[0]}
              y={canvas.h - 360 * spriteScale}
              width={223}
              height={360}
              scale={{ x: spriteScale, y: spriteScale }}
              onPointerDown={() => onTargetCaptured()}
            />
          </>
          }
        </Layer>
        <Layer>
          {/* steam */}
          <Rect
            fillPatternImage={steamImage}
            x={-160}
            y={-80}
            width={1532}
            height={820}
            />
          {lines.map((line, i) => (
            <Line
            key={i}
            points={line.points}
            stroke="#df4b26"
            strokeWidth={60}
            tension={0}
            lineCap="round"
            lineJoin="round"
            opacity={0.5}
            globalCompositeOperation={'destination-out'}
            />
          ))}
            {/* timer */}
            <Text
              text={millisecondsToMinutesAndSeconds(timer)}
              fontSize={40}
              fontFamily="Gotham Pro Bold"
              fill="#ffffff"
              align="center"
              width={100}
              y={30}
              x={canvas.w - 150}
              />
            {/* logo */}
            <Rect
              fillPatternImage={useImage(pictures.logo)[0]}
              x={30}
              y={30}
              width={logo.w}
              height={logo.h}
              />
        </Layer>
      </Stage>
    </div>
  );
}

export default HammamGameEngine;
