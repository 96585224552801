/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import { Button } from 'shared/ui/Button/Button';
import { useDispatch } from 'react-redux';
import {gameScreen, gameRuleShortScreen, gameRuleScreen} from 'app/slices/gameSlice';
import BackGroundLogoOnly from 'shared/ui/BackGroundLogoOnly/BackGroundLogoOnly';
import useDataLayerPush, {dataLayerPush} from "../../../shared/hooks/useDataLayerPush";

const GameRuleScreen = () => {
  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showGameRulesScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const dispatch = useDispatch();

  const onStart = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickGameStart2',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameScreen());
  };

  const onShowShortRules = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'clickShortRules',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });
    dispatch(gameRuleShortScreen());
  };

  return (
    <>
    <Modal>
      <div className={st.wrapper}>
        <h2 className={st.header}>Как играть?</h2>
        <p className={st.text} style={{ marginBottom: 30 }}>
          Тренер открывает дверь в хаммам, жар ударяет ему в лицо — он снимает запотевшие очки и всё
          равно ничего не видит из-за пара. <b>Только ты можешь найти Ксюшу!</b>
          <br />
          <br />
          Свайпай по экрану или кликай кнопкой мыши, чтобы развеять пар. Если увидишь Ксюшу, нажимай
          на нее.
          <br />
          <br />
          <b>
            Всего 3 раунда, с каждым остается всё меньше времени на поиски. Начинаем с 30 секунд.
          </b>
          <br />
          <br />
          <b>Чем больше уровней, тем круче подарок. Начинаем?</b>
        </p>
        <Button label="играть" onClick={onStart} />
        <a 
          type='button' 
          className={st.text_link} 
          style={{ marginTop: 23, cursor: 'pointer' }}
          onClick={onShowShortRules}>
          Краткие правила
        </a>
      </div>
    </Modal>
    <BackGroundLogoOnly />
  </>
)};

export default GameRuleScreen;