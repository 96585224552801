/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'shared/ui/Button/Button';
import { Modal } from 'shared/ui/Modal/Modal';
import st from '../screens.module.scss';
import style from './FeedbackScreen.module.scss';
import { InputText } from 'shared/ui/InputText/InputText';
import { Checkbox } from 'shared/ui/Checkbox/Checkbox';
import { Control, Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { InputMask } from '@react-input/mask';
import React, { useEffect } from 'react';
import BackGroundLogoOnly from 'shared/ui/BackGroundLogoOnly/BackGroundLogoOnly';
import { useDispatch, useSelector } from 'react-redux';
import { endGameScreen, gameRuleShortScreen, resetIsTimeUp, resetLevel, setUserInBase, startExistPage, startGiftAwaitPage, startReceivedGiftPage } from 'app/slices/gameSlice';
import useDataLayerPush, { dataLayerPush } from 'shared/hooks/useDataLayerPush';
import { postMindbox, postPlayer } from 'shared/api/players';
import {RootState} from "../../store";

type Form = {
  name: string;
  phone: string;
  email: string;
  isAcceptPersonal: boolean;
  isAcceptAds: boolean;
};

interface ErrorField {
  errorMessage?: string;
  hasError?: boolean;
  error?: FieldError;
}

interface CustomInputProps extends Record<string, any> {
  placeholder?: string;
  control: Control<Form, any>
  name: keyof Form
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps & ErrorField>((props, forwardRef) => {
  const { placeholder, errorMessage, hasError, control, name } = props;
  
  return (
    <div className={style.input_wrapper + ' ' + (hasError ? style.error : '')}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange },
          fieldState: { error },
        }) => (
          <InputText 
            placeholder={placeholder} 
            error={error}
            ref={forwardRef}
            onChange={onChange}
          />
        )}
      />
      <p className={style.error_message}>{hasError && errorMessage}</p>
    </div>
  );
});

interface CustomCheckboxProps extends Record<string, any> {
  label: string;
  id: string;
  control: Control<Form, any>
  name: keyof Form
}

const CustomCheckbox = (props: CustomCheckboxProps & ErrorField) => {
  const { hasError, errorMessage, label, id, control, name } = props;

  return (
    <div className={style.checkbox_wrapper + ' ' + (hasError ? style.error : '')}>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange },
        }) => (
          <Checkbox 
            id={id} 
            label={label}
            onChange={onChange}
            />
        )}
      />
      <p className={style.error_message}>{hasError && errorMessage}</p>
    </div>
  );
};

const FeedbackScreen = () => {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'showFeedbackScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm<Form>({
    mode: 'onChange'
  });

  const points = useSelector((state: RootState) => state.game.level);
  
  const dispatch = useDispatch()

  const onSubmit: SubmitHandler<Form> = () => {
    postMindbox({
      firstName: getValues("name"),
      email: getValues("email"),
      mobilePhone: getValues("phone"),
      customFields: {
        iMHammam: points + 1
      }
    }).then((res) => {
      dataLayerPush({
        eCategory: 'UX',
        eAction: 'sendFeedbackForm',
        eLabel: 'https://spiritfit.ru/',
        eNI: false,
        event: 'GAEvent',
      });
      
      switch(res.status) {
        case 'usedEmail':
          dispatch(startExistPage());
          break;
        case 'success':
          dispatch(startGiftAwaitPage());
        break;
        case 'error':
          dispatch(startReceivedGiftPage())
        break;
      }

      dispatch(endGameScreen());
    });
  };

  const playAgain = () => {
    dataLayerPush({
      eCategory: 'UX',
      eAction: 'playAgain',
      eLabel: 'https://spiritfit.ru/',
      eNI: false,
      event: 'GAEvent',
    });

    dispatch(gameRuleShortScreen())
    dispatch(resetLevel())
    dispatch(resetIsTimeUp())
  }

  return (
    <>
      <Modal>
        <div className={st.wrapper}>
          <h2 className={style.header} style={{ marginTop: 0, marginBottom: 15 }}>
            Куда прислать подарок?
          </h2>
          <p className={st.text} style={{ marginTop: 0 }}>
            Отправим письмо с подробностями на твою почту.
          </p>
          <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
              control={control}
              placeholder="Имя"
              {...register('name', { required: true })}
              hasError={!!errors.name}
              errorMessage="Введи имя."
              />
            <InputMask
              component={CustomInput}
              mask='+7(___)___-__-__'
              replacement='_'
              control={control}
              placeholder="+7(XХХ)ХХХ-ХХ-ХХ"
              {...register('phone', { required: true, pattern: /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/i })}
              hasError={!!errors.phone}
              errorMessage="Введи номер телефона."
            /> 
            <CustomInput
              control={control}
              placeholder="example@example.ru"
              {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
              hasError={!!errors.email}
              errorMessage="Введи email в формате example@example.com."
              />
            <div className={style.accept}>
              <CustomCheckbox 
                control={control}
                label={'Я соглашаюсь на обработку персональных данных'} 
                id={'0'}
                {...register('isAcceptPersonal', { required: true })}
                errorMessage='Поставь галочку, чтобы получить подарок.'
                hasError={!!errors.isAcceptPersonal}
                />
              <CustomCheckbox 
                control={control}
                label={'Я соглашаюсь на получение рекламных коммуникаций'} 
                id={'1'} 
                {...register('isAcceptAds', { required: true })}
                errorMessage='Поставь галочку, чтобы мы могли отправить подарок.'
                hasError={!!errors.isAcceptAds}
                />
            </div>
            <div className={style.buttons_wrapper}>
              <Button 
                disabled={Object.keys(errors).length !== 0} 
                type="submit" 
                label={'Жду письмо!'}
                 />
            </div>
          </form>
          <a 
            style={{ cursor: 'pointer' }} 
            className={st.text_link} 
            onClick={() => playAgain()}>
            Играть снова
          </a>
        </div>
      </Modal>
      <BackGroundLogoOnly />
    </>
  );
};

export default FeedbackScreen;