/* eslint-disable react-hooks/exhaustive-deps */
import PortraitScreen from 'app/screens/PortraitScreen';
import GameRuleScreen from 'app/screens/GameRuleScreen';
import StrategyScreen from 'app/screens/StrategyScreen';
import FeedbackScreen from 'app/screens/FeedbackScreen';
import { defineScale } from 'app/slices/gamePropertiesSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { useOrientation } from '@uidotdev/usehooks';
import Game from 'shared/game';
import LaunchScreen from 'app/screens/LaunchScreen';
import GameRuleShortScreen from 'app/screens/GameRuleShortScreen';
import EndGame from 'app/screens/EndGameScreen';
import { Screens } from 'shared/types/Screens';

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />}></Route>
    </Routes>
  );
};

const screens = {
  launch: <LaunchScreen/>,
  gameRule: <GameRuleScreen/>,
  gameRuleShort: <GameRuleShortScreen/>,
  game: <Game />,
  strategy: <StrategyScreen/>,
  feedback: <FeedbackScreen/>,
  end: <EndGame/>
};

function Main() {
  const [isLanscape, setIsLanscape] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const currentScreen: Screens = useSelector(
    (state) => (state as any).game.screen
  );
  const dispatch = useDispatch();
  const orientation = useOrientation();
  
  useEffect(() => setIsMounted(true), []);

  useEffect(() => {
    if (isMounted) {
      const w = window.screen.width;
      const h = window.screen.height;
      if (orientation.type === 'portrait-primary') {
        const ar = Math.min(w / 694, h / 1290);
        ar && ar < 1 && dispatch(defineScale(ar * 0.85));
      } else {
        const ar = Math.min(h / 694, w / 1290);
        ar && ar < 1 && dispatch(defineScale(ar * 0.85));
      }
    }
  }, [isMounted]);

  useEffect(() => {
    if (orientation.type === 'portrait-primary') {
      setIsLanscape(false);
    } else {
      setIsLanscape(true);
    }
  }, [orientation]);

  if (!isMounted) return null;

  return !isLanscape ? <PortraitScreen /> : screens[currentScreen];
}
