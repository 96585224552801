import { FieldError } from 'react-hook-form';
import st from './InputText.module.scss';
import { forwardRef } from 'react';

interface InputTextProps extends Record<string, any> {
  placeholder?: string;
  type?: 'email' | 'text';
  error?: FieldError;
  rest?: any;
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>((props: InputTextProps, ref) => {

  const { placeholder, type = 'text', error, ...rest } = props

  return (
    <div>
    <input
      className={st.input}
      placeholder={placeholder}
      type={type}
      data-error={!!error}
      ref={ref}
      {...rest}
      />
  </div>
);

})