import { useEffect } from 'react';

export function useInterval(
  f: () => void,
  onStop: () => void,
  interval: number,
  dep: any,
  isStopped: boolean
) {
  useEffect(() => {
    if (!isStopped) setTimeout(f, interval);
    if (isStopped) onStop();
    // eslint-disable-next-line
  }, dep);
}
