/* eslint-disable jsx-a11y/anchor-is-valid */
import girl from 'shared/assets/desktop/character/steam_girl.png';
import style from './../EndGameScreen.module.scss';
import st from './../../screens.module.scss';
import useDataLayerPush from 'shared/hooks/useDataLayerPush';

const ReceivedGiftPage = () => {

  useDataLayerPush({
    eCategory: 'UX',
    eAction: 'giftRecivedScreen',
    eLabel: 'https://spiritfit.ru/',
    eNI: false,
    event: 'GAEvent',
  });

  return (
    <>
      <img className={style.steam_girl} src={girl} alt="" />
      <h2 className={st.header} style={{ fontSize: 24, marginBottom: 13, marginTop: 34 }}>
        У тебя здорово получилось найти<br/>Ксюшу в этот раз!
      </h2>
      <a className={st.text_link} style={{ textDecoration: 'none', marginBottom: 69 }}>
        Письмо с подарком уже улетело на твою почту.
      </a>
    </>
  );
};

export default ReceivedGiftPage;
