import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Screens } from 'shared/types/Screens';

type GameSlice = {
  screen: Screens;
  numberOfTries: number;
  level: number
  isTimeUp: boolean
  isUserInBase: boolean
  isPromoCodeReceived: boolean
  endGameScreenIndex: number
}

export const gameSlice = createSlice({
  name: 'game',
  initialState: {
    screen: 'launch',
    numberOfTries: 0,
    level: 0,
    isTimeUp: false,
    isUserInBase: false,
    isPromoCodeReceived: false,
    endGameScreenIndex: 0
  } as GameSlice,
  reducers: {
    gameScreen: (state) => {
      state.screen = 'game';
    },
    launchScreen: (state) => {
      state.screen = 'launch';
    },
    gameRuleScreen: (state) => {
      state.screen = 'gameRule';
    },
    gameRuleShortScreen: (state) => {
      state.screen = 'gameRuleShort';
    },
    strategyScreen: (state) => {
      state.screen = 'strategy';
    },
    startExistPage: (state) => {
      state.endGameScreenIndex = 0;
    },
    startGiftAwaitPage: (state) => {
      state.endGameScreenIndex = 1;
    },
    startReceivedGiftPage: (state) => {
      state.endGameScreenIndex = 2;
    },
    feedbackScreen: (state) => {
      state.screen = 'feedback';
    },
    endGameScreen: (state) => {
      state.screen = 'end';
    },
    resetLevel: (state) => {
      state.level = 0;
    },
    resetIsTimeUp: (state) => {
      state.isTimeUp = false;
    },
    setIsTimeUp: (state) => {
      state.isTimeUp = true;
    },
    incrementLevel: (state) => {
      state.level += 1;
    },
    increaseNumberOfTries: (state) => {
      state.numberOfTries += 1;
    },
    setUserInBase: (state, action: PayloadAction<boolean>) => {
      state.isUserInBase = action.payload
    },
  },
});

export const { 
  gameScreen, 
  increaseNumberOfTries,
  gameRuleScreen,
  gameRuleShortScreen,
  incrementLevel,
  strategyScreen,
  endGameScreen,
  launchScreen,
  setIsTimeUp,
  resetLevel,
  startGiftAwaitPage,
  startReceivedGiftPage,
  startExistPage,
  feedbackScreen,
  resetIsTimeUp,
  setUserInBase,
} = gameSlice.actions;

export default gameSlice.reducer;
