import st from './Button.module.scss';

interface ButtonProps {
  onClick?: () => void;
  label?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
}

export const Button = ({ onClick, label, type = 'button', disabled }: ButtonProps) => (
  <button type={type} className={st.button} disabled={disabled} onClick={onClick}>
    {label}
  </button>
);
