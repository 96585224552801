const PositionsX = [
    [
        [100, 400, 940],
        [970, 460, 100],
        [670, 360, 100],
        [291, 10, 633],
    ],
    [
        [885, 102, 325],
        [925, 325, 80],
        [887, 586, 356],
        [56, 323, 573],
    ],
    [
        [109, 453, 775],
        [12, 295, 603],
        [234, 935, 523],
    ],
]

export default PositionsX;