import { type ReactNode } from 'react';
import st from './Modal.module.scss';

type TProps = {
  children: ReactNode;
};

export const Modal = ({ children }: TProps) => {
  return (
    <div className={st.back}>
      <div className={st.wrapper}>{children}</div>
    </div>
  );
};
